import React from 'react'
import { Gallery, Profession, Time } from '../Components'

const Timeline = () => {
  return (
    <div>
    <Profession/>
    <Time/>
    <Gallery />
    </div>
  )
}

export default Timeline

import React from "react";
const Gallery = () => {

  const Lists = [
    {
      img: "../Assets/img1.jpg"
    },
    {
      img: "../Assets/img2.jpg"
    },
    {
      img: "../Assets/img5.JPG"
    },
    {
      img: "../Assets/img4.JPG"
    },
    {
      img: "../Assets/img3.jpg"
    },
    {
      img: "../Assets/img6.jpg"
    },
    {
      img: "../Assets/img7.jpg"
    },
    {
      img: "../Assets/img8.jpg"
    },
    {
      img: "../Assets/img21.jpg"
    },
    {
      img: "../Assets/img10.JPG"
    },
    {
      img: "../Assets/img11.jpg"
    },
    {
      img: "../Assets/img12.jpg"
    },
    {
      img: "../Assets/img13.jpg"
    },
    {
      img: "../Assets/img14.jpg"
    },
    {
      img: "../Assets/img15.jpg"
    },
    {
      img: "../Assets/img16.jpg"
    },
    {
      img: "../Assets/img17.jpg"
    },
    {
      img: "../Assets/img18.jpg"
    },
    {
      img: "../Assets/img19.jpg"
    },
    {
      img: "../Assets/img20.jpg"
    },
    
    
  ]
  return (
    <div>
      <div className="md:text-3xl lg:text-6xl text-4xl font-tragic text-blue pt-10 text-center">
        Gallery
      </div>
      <div className="py-[10%] gap-y-6 gap-[2%] grid md:grid-cols-4 grid-cols-2 px-[10%]">
      {Lists.map((List) => (
        <div className="container flex justify-center items-center rounded-md overflow-hidden">
        <img src={List.img} className=" rounded-md w-full h-full" alt="" />
      </div>
      ))}
      
    </div>
    </div>
  );
};

export default Gallery;
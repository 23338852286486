import React from "react";
import Typewriter from "typewriter-effect";

const Trainer = () => {
  return (
    <div className="pb-10 md:pb-0">
      <h1 className="text-center md:text-3xl lg:text-6xl text-4xl text-blue font-tragic">
        Meet Your Trainer
      </h1>
      <div className=" px-[10vw] grid md:grid-cols-2">
        <div className="lg:pt-[8vw] pt-[4vw] order-2 md:order-1 leading-realxed lg:leading-normal lg:text-[1.5vw] md:text-lg text-2xl relative flex flex-col gap-2 font-incon text-justify ">
          {/* <p>I'm Ankita, your French Linguistic Expert and TEF Coach, living in Toronto. I'm here to help you speak fluent French and ace TEF/TCF exams!</p>
          <p>Living in Canada, I love exploring its beautiful landscapes and trying out different cuisines. </p>
          <p>I'm passionate about languages and enjoy learning about different cultures.</p>
          <p className=" lg:w-[70%]  leading-loose">
          Let's explore the French world together!
          </p> */}
          <Typewriter
            onInit={(typewriter) => {
              typewriter
                .typeString(
                  "I'm Ankita, your French Linguistic Expert and TEF Coach, living in Toronto. I'm here to help you speak fluent French and ace TEF/TCF exams!"
                )
                .pauseFor(1000) // Pause for 1 second before typing the next string
                .start()
                .typeString(
                  "\n\nLiving in Canada, I love exploring its beautiful landscapes and trying out different cuisines."
                )
                .start()
                .pauseFor(1000) // Pause for 1 second before typing the next string
                .start()
                .typeString(
                  "\n\nI'm passionate about languages and enjoy learning about different cultures."
                )
                .start()
                .pauseFor(1000) // Pause for 1 second before typing the next string
                .start()
                .typeString(
                  "\n\nLet's explore the French world together!"
                )
                .start();
            }}
          />
          <img
            className="absolute w-[30%] bottom-8 -left-[6vw] float_up"
            src="/Assets/grp24.png"
            alt=""
          />
          <img
            className="absolute w-[35%] -top-[8vw] left-0 float_up"
            src="/Assets/grp26.png"
            alt=""
          />
        </div>
        <div className="relative order-1 md:order-2 flex flex-col justify-center items-center float_up">
          <img className="h-[80%]" src="/Assets/trainer.png" alt="" />

          <img
            className="md:absolute hidden w-[20%] -left-[4vw] top-[12vw] float_side"
            src="/Assets/grp25.png"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default Trainer;

import React from "react";
import M1B from './M1B.png'
import M1BL from './m1BL.png'

const Module = () => {
  return (
    <div className="px-[8vw] md:h-screen py-[6vw] relative">
    <div className="relative">

      <h1 className=" pb-[6vw] text-center lg:text-6xl text-4xl font-tragic text-blue ">

        What we train ?
      </h1>
      <img src="/Assets/grp79.png" className=" float_up absolute w-[10%] sm:w-[5%] top-0 right-[15%] sm:right-[24%]" alt="" />
    </div>
      <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-6 lg:gap-0 justify-items-center">
        <div className="relative">
          <div className="absolute  flex justify-items-center flex-col pt-[20%] px-8 w-full -translate-x-4">
            <h1 className="font-averia text-2xl text-center pt-4">Speaking</h1>
            <ul className="font-incon list-disc text-lg pl-6 pt-2 leading-relaxed">
              <li>Personalized feedback</li>
              <li>Individual attention</li>
              <li>Interactive activities</li>
              <li>Self-study Resources</li>
              <li>Experienced trainers</li>
            </ul>
          </div>
          <div className="relative">
            <img
              className=" absolute hover:opacity-0"
              src={M1B}
              alt=""
            />
            <img className="" src={M1BL} alt="" />
          </div>
        </div>
        <div className="relative">
          <div className="absolute pt-[20%] px-8 w-full -translate-x-4">
            <h1 className="font-averia text-2xl text-center pt-4">Writing</h1>
            <ul className="font-incon list-disc text-lg pl-6 pt-2 leading-relaxed">
              <li>Structured lessons</li>
              <li>Individual Feedback</li>
              <li>writing strategies</li>
              <li>writing resources</li>
              <li>Flexible schedules</li>
            </ul>
          </div>
          <div className="relative">
            <img
              className=" absolute hover:opacity-0"
              src={M1B}
              alt=""
            />
            <img className="" src={M1BL} alt="" />
          </div>
        </div>
        <div className="relative">
          <div className="absolute pt-[20%] px-8 w-full -translate-x-4">
            <h1 className="font-averia text-2xl text-center pt-4">Listening</h1>
            <ul className="font-incon list-disc text-lg pl-6 pt-2 leading-relaxed">
            <li>Authentic materials</li>
              <li>Accent Practice</li>
              <li>Active Listening</li>
              <li>Mock Exams</li>
              <li>Focus on main ideas</li>
            </ul>
          </div>
          <div className="relative">
            <img
              className=" absolute hover:opacity-0"
              src={M1B}
              alt=""
            />
            <img className="" src={M1BL} alt="" />
          </div>
        </div>
        
        
        
      </div>
      <img src="/Assets/grp80.png" className="absolute w-[10%] sm:w-[5%] top-[42%] right-[7%] float_side" alt="" />
      <div>
      <img src="/Assets/grp81.png" className="sm:w-[6%] w-[12%] absolute bottom-[15%] right-[5%] lg:right-[10%] float_right" alt="" />
      <img src="/Assets/grp82.png" className="w-[30%] md:w-[40%] lg:w-[17%] absolute bottom-[18%] lg:bottom-[10%] sm:bottom-[14%]  sm:-left-[0%] md:left-[55%] lg:-left-[0%] float_up" alt="" />
      <img src="/Assets/grp83.png" className="sm:w-[7%] w-[14%] absolute sm:bottom-[5%] bottom-0  lg:bottom-[20%] right-[6%] sm:right-[50%] float_down" alt="" />

      </div>
    </div>
  );
};

export default Module;

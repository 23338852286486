import React from "react";

const Train = () => {
  return (
    <div
      className="lg:h-screen relative hover: "
      style={{
        backgroundImage: `url('/Assets/bg2.png')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="lg:pb-10">
        <img
          src="/Assets/grp45.png"
          className="absolute right-[33%] w-[15vw] top-20 float_up"
          alt=""
        />
        <img
          src="/Assets/grp40.png"
          className="absolute  w-[8vw] left-[16%] top-20 right float_down"
          alt=""
        />
      </div>
      <div className="grid max-w[1580px] mx-auto px-[10vw] py-[10vw] md:grid-cols-2">
        <div>
          <h1 className="text-blue pt-20 md:text-3xl lg:text-6xl text-4xl font-tragic">
            How we train
          </h1>
          <h1 className=" lg:pl-1 pt-5 md:text-2xl lg:text-5xl text-3xl pb-[1vw] md:text-[2.5vw] text-md font-averia">
            Know our tips & tricks{" "}
          </h1>
          <p className=" sm:w-[90] lg:w-[70%] lg:leading-relaxed text-justify font-inria lg:text-[1.5vw] md:text-lg text-2xl lg:pl-2 pb-10">
            Explore the Magnificent World of Canada, Embrace the Allure of
            French Language, and Conquer the TEF Exam with Confidence. Let's
            Shape Your Canadian Story Together!
          </p>
        </div>
        <div className="flex relative justify-center pb-12 md:pb-0 items-center ">
          <div className="w-full flex relative justify-center items-center">
            <img className="w-[55%]" src="/Assets/img3.png" alt="" />
            <img
              src="/Assets/grp43.png"
              className="absolute w-[10vw] md:w-[5vw] float_di bottom-10 2xl:left-0 2xl:bottom-20 left-[4vw] md:bottom-[3vw]"
              alt=""
            />
            <img
              src="/Assets/grp40.png"
              className="absolute 2xl:-left-20 float_left 2xl:top-40 top-[20vw] md:top-[8vw] left-[1vw] w-[16vw] md:w-[10vw]"
              alt=""
            />
            <img
              src="/Assets/grp46.png"
              className="absolute 2xl:left-40 2xl:top-10 float_down left-[15vw] md:left-[8vw] top-[3vw] w-[8vw] md:w-[3.5vw] "
              alt=""
            />
            <img
              src="/Assets/grp36.png"
              className="absolute 2xl:right-40 2xl:top-20 top-[5vw] float_up right-[16vw] md:right-[8vw] w-[8vw] md:w-[3vw] "
              alt=""
            />
            <img
              src="/Assets/grp44.png"
              className="absolute 2xl:right-20 2xl:bottom-32 md:right-[6vw] float_right right-[10vw] bottom-[10vw] md:bottom-[5vw] w-[10vw]  md:w-[4vw] "
              alt=""
            />
            <img
              src="/Assets/grp42.png"
              className="absolute -left-[12vw] md:-bottom-20 float_up -bottom-10 w-[20vw] md:w-[9vw] "
              alt=""
            />
            <img
              className="absolute -bottom-[13vw] md:-bottom-[6vw] w-[65%] md:left-30"
              src="/Assets/grp38.png"
              alt=""
            />
            <img
              className="absolute left-[6vw] md:left-[5vw] -bottom-[11vw]  md:-bottom-[4vw]  w-[40%]"
              src="/Assets/grp37.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Train;

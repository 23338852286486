import { BrowserRouter, Route, Routes } from "react-router-dom";
import Contact from "./Pages/Contact";
import Home from "./Pages/Home";
import Info from "./Pages/Info";
import Timeline from "./Pages/Timeline";
import What from "./Pages/What";
import Navbar from "./Layout/Navbar";
import { Footer, Sidebar } from "./Layout";

function App() {
  return (
    <BrowserRouter>
      <div className="md:grid grid-cols-12   ">
        <div className="col-span-1 hidden md:grid sticky top-0  left-0 z-50 h-screen bg-[#FCC0A8]">
          <Sidebar />
        </div>
        <div className="col-span-11">
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/what" element={<What />} />
            <Route path="/info" element={<Info />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/timeline" element={<Timeline />} />
          </Routes>
          <Footer />
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;

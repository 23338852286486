import React from 'react'

const Method = () => {
  const List=[
    {
      img:"/Assets/m3.png",
      text: "One-on-one session"
    },
    {
      img:"/Assets/m1.png",
      text: "Task based learning"
    },
    {
      img:"/Assets/m2.png",
      text: "Error correction & feedback"
    },
    {
      img:"/Assets/m4.png",
      text: "Customised reading material"
    },
    {
      img:"/Assets/m5.png",
      text: "Speaking workshops"
    },
    {
      img:"/Assets/m6.png",
      text: "Vocabulary Building"
    },
    {
      img:"/Assets/m7.png",
      text: "Pronunciation Practice"
    },
    {
      img:"/Assets/m8.png",
      text: "Mock Tests"
    },
  ]
  return (
    <div className='bg-lightgry relative'>
    <div className=' px-[16vw] mx-auto py-20'>
      <div>
      <h1 className='text-center md:text-3xl lg:text-6xl text-4xl font-tragic text-blue'>Our Training Methods</h1>
      <p className='text-center font-semibold py-16 lg:text-[1.5vw] md:text-lg text-2xl font-incon text-black'>We use world class training material and help our students master the modules.</p>
      </div>
      <div className='flex items-center justify-center gap-16 flex-wrap'>
      
      {
        List.map((Lists)=>(
          <div className='min-w-[28%] flex justify-center items-center flex-col'>
            <div className=''>
            <img src={Lists.img} className='w-[34vw] xs:w-[25vw] sm:w-[14vw] hover:scale-105' alt="" />
            </div>
            <div>
            <h1 className='lg:text-[1.3vw] md:text-lg text-2xl text-center font-semibold font-averia'>{Lists.text}</h1>
            </div>
            
          </div>
        ))
      }

      </div>
    </div>
    <img src="/Assets/grp47.png" className='absolute top-[10vw] left-[10vw] float_up' alt="" />
    <img src="/Assets/grp48.png" className='absolute md:top-[30vw] top-[40vh] right-[6vw] float_down' alt="" />
    <img src="/Assets/grp50.png" className='absolute md:bottom-[10vw] bottom-[30vh] w-[20vw] sm:w-[8vw] right-[6vw]' alt="" />
    <img src="/Assets/grp51.png" className='absolute bottom-[15vw] left-[6vw]' alt="" />
    <img src="/Assets/grp49.png" className='absolute top-[30vw] right-[37vw]' alt="" />
    <img src="/Assets/grp52.png" className='absolute sm:top-[38vw] top-[60vh] left-[31vw]' alt="" />
    
      
    </div>
  )
}

export default Method

import React from "react";

const Profession = () => {
  return (
    <div>
      <div
        className="relative lg:pt-10 pt-20"
        style={{
          backgroundImage: `url('/Assets/bg3.png')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="grid max-w[1580px] mx-auto px-[10vw] py-[5vw] lg:grid-cols-2">
          <div className="pt-[4vw] order-2">
            <h1 className=" md:text-3xl lg:text-6xl text-4xl text-red  pt-10 font-tragic leading-relaxed">
              A Teacher, An Explorer And A Lover of French Cinema
            </h1>

            <div className=" sm:w-[90] lg:w-[] text-justify font-incon lg:text-[1.5vw] md:text-lg text-2xl pt-4 lg:pl-2 pb-10">
              <span>Meet Ankita Agrawal, Founder - Influencer Anks.</span>
              <ul className="list-disc pl-5">
                <li>DALF C1 Certified</li>
                <li>Bilingual Project Leader in Canada</li>
                <li>Passionate about helping others secure Canadian PR</li>
              </ul>
              <span>Join us on your language journey for TEF/TCF success.</span>
            </div>
            <div className="relative flex flex-col justify-center items-start">
              <button className="absolute text-md text-white left-[7%] font-averia xs:left-[5%] md:left-[8%] lg:left-[5%] xl:left-[5%]">
                Scroll for more!
              </button>
              <img src="/Assets/grp34.png" className="w" alt="" />
            </div>
          </div>
          <div className="flex relative justify-center pb-12 md:pb-0 items-center ">
            <div className="w-full flex relative   items-center justify-center">
              <img className="w-[60%]" src="/Assets/tv.png" alt="" />

              <img
                src="/Assets/grp77.png"
                className="absolute bottom-0 left-0 w-[12%] float_up"
                alt=""
              />
              <img
                src="/Assets/grp76.png"
                className="absolute bottom-[20%] -left-[20%] w-[20%] float_right"
                alt=""
              />
              <img
                src="/Assets/grp54.png"
                className=" transform rotate-45 absolute bottom-[70%] left-[10%] w-[14%]"
                alt=""
              />
              <img
                src="/Assets/grp25.png"
                className="absolute top-[13%] left-[0%] w-[23%] float_left"
                alt=""
              />
              <img
                src="/Assets/grp70.png"
                className="absolute top-[8%] right-[15%] w-[5%] float_down"
                alt=""
              />
              <img
                src="/Assets/grp71.png"
                className="absolute top-[30%] right-[12%] w-[2%] float_right"
                alt=""
              />
              <img
                src="/Assets/grp69.png"
                className=" absolute top-[42%] w-[30%] right-[0%]"
                alt=""
              />
              <img
                src="/Assets/grp72.png"
                className=" absolute bottom-[22%] float_right right-10 bottom-6 w-[6%]"
                alt=""
              />
            </div>
          </div>
        </div>
        <img
          src="/Assets/grp73.png"
          className=" absolute top-[45%] right-[0%] w-[7%] float_down"
          alt=""
        />
      </div>
    </div>
  );
};

export default Profession;

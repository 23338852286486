import React from 'react'

const Next = () => {
  return (
    <div className='py-[8vw] relative'>
    <h1 className=" pb-[6vw] text-center md:text-3xl lg:text-6xl text-4xl font-tragic text-blue ">
    TEF done! What's next ?
      </h1>
    <div className='grid place-content-center xs:grid-cols-2 lg:grid-cols-3 gap-16 px-[20vw]'>
    <img className='hover:scale-105' src="/Assets/next1.png" alt="" />
    <img className='hover:scale-105' src="/Assets/next2.png" alt="" />
    <img className='hover:scale-105' src="/Assets/next3.png" alt="" />
    <img className='hover:scale-105' src="/Assets/next4.png" alt="" />
    <img className='hover:scale-105' src="/Assets/next5.png" alt="" />
    <img className='hover:scale-105' src="/Assets/next6.png" alt="" />
      
    </div>
    <img src="/Assets/grp62.png" className='absolute top-[5vw] w-[10vw] right-0 float_up' alt="" />
      
    </div>
  )
}

export default Next

import React, {useState} from "react";
import { Link } from "react-router-dom";
import {FaBars} from "react-icons/fa";
import {ImCross } from "react-icons/im";

const Index = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  }

  const connectButton = document.getElementsByClassName('topmate-button');
  // console.log(connectButton);

  const connectOpen = () => {
    if(connectButton[0]){
      connectButton[0].click();
      // console.log(connectButton);
    }
  }

  return (
    <section>

      <div className="fixed w-full z-20">
      <div className="bg-lightgry flex justify-between py-2 px-10 shadow-xl">
        <Link to="/">
          <div className="flex gap-4 items-center ">
            <img src="/Assets/logo.png" className="md:w-16 w-10" alt="" />
            <p className="md:text-2xl text-lg font-comic text-darkblue">Influencer Anks</p>
          </div>
        </Link>
        <div className="pr-[10vw]" onClick={connectOpen}>
          <div className="pr-[15%] hidden md:flex  justify-center  relative" >
            <img src="/Assets/grp9.png" className="py-2 float_up" alt="" />
            <div className="text-white absolute top-6 text-sm float_up">
              <p className="font-averia text-xl">Talk to an Expert</p>
            </div>
          </div>
        </div>
        <div className="md:hidden flex items-center" onClick={handleNav}>

            {
              !nav ? <FaBars className="text-red" /> : <ImCross className="text-red" />
            }
            </div>
      </div>
      </div>

      <div className={nav ? "bg-white/[0.9] h-screen fixed z-30 w-[70%] ease-in-out duration-1000 pt-[20%] px-5 text-center lg:hidden" : "hidden"}>
        {links.map((link) => (
          <div className="py-7 border-b-[1px] border-red" onClick={handleNav}>
            <Link to={link.link} onClick={scrollToTop}>
              <button className="font-medium text-red text-xl font-averia">{link.text}</button>
            </Link>
          </div>
        ))}
        <div className="py-7" onClick={handleNav}>
          <Link to="/contact" onClick={scrollToTop}>
            <button className="text-white w-full bg-red px-6 py-2 rounded-xl border-2 border-white hover:bg-primary hover:text-white font-medium">
              Contact Us
            </button>
          </Link>
        </div>
      </div>
            </section>
  );
};

export default Index;


const links = [
  {
    text: "Who we are?",
    link: "/"
  },
  {
    text: "What we train?",
    link: "/what"
  },
  {
    text: "How we train?",
    link: "/info"
  },
  {
    text: "My Journey",
    link: "/timeline"
  },
]
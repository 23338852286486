import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { FreeMode, Autoplay } from "swiper/modules";

const Stories = () => {
  const List = [
    {
      img: "../Assets/tanvi.png",
      name: "Tanvi Arora",
      // score
      sc_wricomp: "565",
      sc_oralcomp: "508",
      sc_wriexp: "477",
      sc_oralexp: "485",
      // grade
      gr_wricomp: "C1",
      gr_oralcomp: "C1",
      gr_wriexp: "B2",
      gr_oralexp: "B2",
    },
    {
      img: "../Assets/neeru.png",
      name: "Neeru Panchal",
      // score
      sc_wricomp: "490",
      sc_oralcomp: "498",
      sc_wriexp: "502",
      sc_oralexp: "510",
      // grade
      gr_wricomp: "B2",
      gr_oralcomp: "B2",
      gr_wriexp: "C1",
      gr_oralexp: "C1",
    },
    {
      img: "../Assets/tushar.png",
      name: "Tushar Dhingra",
      // score
      sc_wricomp: "518",
      sc_oralcomp: "471",
      sc_wriexp: "495",
      sc_oralexp: "470",
      // grade
      gr_wricomp: "C1",
      gr_oralcomp: "B2",
      gr_wriexp: "B2",
      gr_oralexp: "B2",
    },
    {
      img: "../Assets/navrose.png",
      name: "Navrose Kaur",
      // score
      sc_wricomp: "469",
      sc_oralcomp: "453",
      sc_wriexp: "517",
      sc_oralexp: "470",
      // grade
      gr_wricomp: "B2",
      gr_oralcomp: "B2",
      gr_wriexp: "C1",
      gr_oralexp: "B2",
    },
    {
      img: "../Assets/kanika.png",
      name: "Kanika Saxena",
      // score
      sc_wricomp: "534",
      sc_oralcomp: "488",
      sc_wriexp: "458",
      sc_oralexp: "500",
      // grade
      gr_wricomp: "C1",
      gr_oralcomp: "B2",
      gr_wriexp: "B2",
      gr_oralexp: "C1",
    },
    {
      img: "../Assets/maya.png",
      name: "Maya",
      // score
      sc_wricomp: "555",
      sc_oralcomp: "454",
      sc_wriexp: "450",
      sc_oralexp: "481",
      // grade
      gr_wricomp: "C1",
      gr_oralcomp: "B2",
      gr_wriexp: "B2",
      gr_oralexp: "B2",
    },
    {
      img: "../Assets/ojaswee.png",
      name: "Dr Ojaswee Nakhawa",
      // score
      sc_wricomp: "492",
      sc_oralcomp: "464",
      sc_wriexp: "500",
      sc_oralexp: "481",
      // grade
      gr_wricomp: "B2",
      gr_oralcomp: "B2",
      gr_wriexp: "C1",
      gr_oralexp: "B2",
    },
    {
      img: "../Assets/rubal.png",
      name: "Rubal",
      // score
      sc_wricomp: "569",
      sc_oralcomp: "485",
      sc_wriexp: "503",
      sc_oralexp: "485",
      // grade
      gr_wricomp: "C1",
      gr_oralcomp: "B2",
      gr_wriexp: "C1",
      gr_oralexp: "B2",
    },
    {
      img: "../Assets/divya.png",
      name: "Divya",
      // score
      sc_wricomp: "569",
      sc_oralcomp: "485",
      sc_wriexp: "503",
      sc_oralexp: "485",
      // grade
      gr_wricomp: "C1",
      gr_oralcomp: "B2",
      gr_wriexp: "C1",
      gr_oralexp: "B2",
    },
    {
      img: "../Assets/pushap.png",
      name: "Pushap Kamal",
      // score
      sc_wricomp: "552",
      sc_oralcomp: "494",
      sc_wriexp: "466",
      sc_oralexp: "528",
      // grade
      gr_wricomp: "C1",
      gr_oralcomp: "B2",
      gr_wriexp: "B2",
      gr_oralexp: "C1",
    },
  ];
  return (
    <div className="pb-[1vw] pt-[4vw] bg-lightgry">
      <h1 className="text-center md:text-3xl lg:text-6xl text-4xl text-blue pb-12 font-tragic">
        Success Stories
      </h1>
      <div className="relative">
        <div className="flex flex-col  items-center">
          <div className="w-full   ">
            <Swiper
              slidesPerView={2}
              centeredSlides={true}
              grabCursor={true}
              spaceBetween={80}
              freeMode={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                  spaceBetween: 30,
                },
                640: {
                  slidesPerView: 1,
                  spaceBetween: 30,
                },

                1024: {
                  slidesPerView: 2,
                  spaceBetween: 80,
                },
              }}
              modules={[FreeMode, Autoplay]}
              className="sm:h-[73vh] flex  justify   "
            >
              {List.map((Lists) => (
                <SwiperSlide className="pt-6">
                  <div className="relative">
                    <div className="mt-6 lg:w-full w-[95%]  mx-auto  ">
                      <div className=" gap-4 sm:py-12  xl:py-16 px-16 shadow-5xl shadow-[#FFA488]/40  rounded-3xl p-6  md:grid  grid-cols-3 bg-darkergry  ">
                        <div className="lg:text-[1.3vw] md:text-base text-2xl">
                          <h1 className="text-red pb-6 font-tragic">
                            {Lists.name}
                          </h1>
                          <div className="relative maxw-[90%] pb-5 md:pb-0">
                            <img
                              src={Lists.img}
                              className="w-[85%] sm:left-10 md:left-0 xs:left-4 top-0"
                              alt={Lists.name}
                            />
                            {/* <img
                              className="w-[99%] h-full"
                              src="/Assets/grp55.png"
                              alt=""
                            /> */}
                          </div>
                        </div>
                        <div className="col-span-2 lg:text-[1.3vw] md:text-base text-xl font-incon">
                          <div className="grid grid-cols-5 text-blue font-tragic">
                            <p className=" col-span-3">Subject</p>

                            <p className="">Score</p>
                            <p className="">Grade</p>
                          </div>
                          <div className="flex flex-col justify-center text-blue pt4 gap-[1.4vw] sm:pt-6 sm:gap-[1.7vw] md:gap-[1.7vw] lg:gap-[1vw] xl:gap-[1.7vw]">
                            <div className="grid grid-cols-5  font-semibold   ">
                              <p className=" col-span-3">
                              Reading
                              </p>

                              <p className="">{Lists.sc_wricomp}</p>
                              <p className="">{Lists.gr_wricomp}</p>
                            </div>
                            <div className="grid grid-cols-5  font-semibold   ">
                              <p className="col-span-3">
                              Listening
                              </p>
                              <p>{Lists.sc_oralcomp}</p>
                              <p>{Lists.gr_oralcomp}</p>
                            </div>
                            <div className="grid grid-cols-5  font-semibold   ">
                              <p className="col-span-3">
                              Writing
                              </p>
                              <p>{Lists.sc_wriexp}</p>
                              <p>{Lists.gr_wriexp}</p>
                            </div>
                            <div className="grid grid-cols-5  font-semibold   ">
                              <p className="col-span-3">Speaking</p>
                              <p>{Lists.sc_oralexp}</p>
                              <p>{Lists.gr_oralexp}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <img
                        className="absolute lg:-right-16 lg:-top-16 -top-14 md:right-8 right-0"
                        src="/Assets/grp53.png"
                        alt=""
                      />
                      <img
                        className="absolute -left-4   xs:-left-16 -bottom-12"
                        src="/Assets/grp54.png"
                        alt=""
                      />
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stories;

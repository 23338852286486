import React from "react";
// import Group16 from "../Assets/Group16.png";
// import Group17 from "Assets/Group17.png";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { FreeMode, Autoplay } from "swiper/modules";

const Testimonials = () => {
  const List = [
    {
      img: "../Assets/tanvi.png",
      name: "Tanvi Arora",

      para: "Earlier I have a huge fear of speaking like Every have,but after taking classes from Ankita di,it was a cake walk for me.Her speaking sessions are really very good. For me it was the most easiest sections because of the notes and tricks provided by Ankita Di.",
    },
    {
      img: "../Assets/tushar.png",
      name: "Tushar Dhingra",

      para: "I can't thank you enough for all the support and guidance even at odd times... especially in CE and EO. Your tips and tricks are just to the point and what it takes to clear the test. Again it wouldn't have been possible without your guidance. Again, many thanks.",
    },
    {
      img: "../Assets/navrose.png",
      name: "Navrose Kaur",

      para: "A big thankss to Ankita Mam for helping me achieving this great score. I started learning french from scratch last June and I'm able to achieve this in less than 11 months. All because of TEF group and specifically Ankita mam for speaking.",
    },
    {
      img: "../Assets/kanika.png",
      name: "Kanika Saxena",

      para: "Thank you so so much, this wouldn't have happened without you. You helped in every possible way and was so so patient with me. All your tips, tricks and points helped me all through the way! I can't thank you enough and my success is only because of you",
    },
    {
      img: "../Assets/maya.png",
      name: "Maya",

      para: "Thank you Ankita and Heena... I owe this success to both if you.... 🩷🩷🩷",
    },
    {
      img: "../Assets/ojaswee.png",
      name: "Dr Ojaswee Nakhawa",

      para: "Thanks a lot ma'am, all because of guidance I could clear my exam. You and Heena helped me sooooooooo much. You both really pushed me hard to try again and again. Thank you!",
    },
    {
      img: "../Assets/divya.png",
      name: "Divya",

      para: "Thank you for your invaluable mentoring Ankita Mam. I’ve had a lot of absorbing getting to know your valuable feedback at each step , infact I’ve learned so much from you just by taking 10 classes in a month. I’m grateful to have you as my TEF Coach.",
    },
    {
      img: "../Assets/rubal.png",
      name: "Rubal",

      para: "This journey has been challenging, but the support and guidance from each one of you and these dedicated teachers made it possible. I want to express my heartfelt gratitude to Ankita mam, Heena mam and Kanika for their unwavering support, patience, and commitment to this success.",
    },
    {
      img: "../Assets/pushap.png",
      name: "Pushap Kamal",

      para: "I would like to thank you for the guidance you provided for the TEF exam. Your class was a morale booster and I also appreciate your flexibility to take classes even late in the evening and at very short notice. Thanks a lot for all the support and encouragement.",
    },
  ];

  const isMobile = window.innerWidth <= 700;
  return (
    <div
      className=" relative"
      style={{
        backgroundImage: `url('/Assets/grp35.png')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="flex flex-col pb-20 md:pb-0  items-center">
        <h1 className="md:text-3xl lg:text-6xl text-4xl text-blue text-center font-tragic pt-24 pb-10">
          Testimonials
        </h1>
        <div className="">
          <Swiper
            slidesPerView={2}
            centeredSlides={true}
            grabCursor={true}
            spaceBetween={80}
            // freeMode={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}

            breakpoints={{
              0: {
                slidesPerView: 1,
                spaceBetween: 30,
              },
              640: {
                slidesPerView: 1,
                spaceBetween: 30,
              },

              1024: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
              1366: {
                spaceBetween: 80,
              },
            }}
            modules={[FreeMode, Autoplay]}
            className="sm:h-[55vh] max-w-[1280px] w-[90vw] "
          >
            {List.map((Lists) => (
              <SwiperSlide className="max-w-[90vw] md:px-10 lg:px-0">
                <div className="relative w-full">
                <div className="absolute grid md:grid-cols-3 w-full items-center py-6 md:py-4 px-10">
                    <div className="md:col-span-2">
                      <h1 className="font-tragic lg:text-[1.5vw] md:text-md text-3xl text-red">{Lists.name}</h1>
                      <p className=" md:text-base text-lg font-incon">{Lists.para}</p>
                    </div>
                    <div className="pt-5 flex justify-center items-center   md:pt-0">
                      <img className="w-[30vw]" src={Lists.img} alt={Lists.name} />
                    </div>
                  </div>
                  <div className="">
                    <img className="md:block hidden" src="/Assets/Group18.png" alt="" />
                    <img className="md:hidden block" src="/Assets/Group111.png" alt="" />
                  </div>

                  {/* <div className="xl:mt-4 absolute top-0   pl-[4vw]  mx-auto ">
                    <div className="   place-content-center place-items-center shadow-5xl shadow-[#FFA488]/40  rounded-3xl  grid sm:grid-cols-2  ">
                      <div className="flex justify-center items-center">
                        <div className="">
                          <h1 className="xl:text-[2vw] text-red font-tragic">
                            {Lists.name}
                          </h1>

                          <p className="xl:py-4 text-sm py-3 md:py-2 lg:px-0 md:px-5 text-gray-900">
                            {Lists.para}
                          </p>
                        </div>
                      </div>

                      <div className="flex pb-2 items-center justify-center">
                        <img
                          src={Lists.img}
                          className="xl:w-[70%] md:w-[60%] lg:pt-[1vw]"
                          alt=""
                        />
                      </div>
                    </div>
                  </div> */}

                  
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        {/* <div className='relative flex flex-col justify-center items-center'>
                    <div className=' flex justify-center items-center '>
                    <img className='' src="/Assets/Group18.png" alt="" />

                    </div>
                
                <div  className="mt-4 absolute top-0 flex justify-center items-center flex-col  pl-[5vw]   ">

                  <div className="   place-content-center place-items-center shadow-5xl shadow-[#FFA488]/40  rounded-3xl  grid sm:grid-cols-2  ">
                  <div className="flex justify-center items-center">
                  <div className=" flex justify-center items-center flex-col">
                  <div className='flex justify-start items-start'>

                    <h1 className="xl:text-2xl  text-xl text-[#FF701F]">Abhishek sibgh kiara</h1>
                  </div>
                   
                    <p className="xl:py-4 py-3 md:py-4 lg:px-0  text-gray-900">orem ipsum dolor sit amet consectetur, adipisicing elit. Tenetur nisi ad hic doloremque nam earum repudiandae ea error aut eos quas, dolores vitae possimus repellendus quis necessitatibus totam dicta, explicabo fugiat recusandae commodi mollitia harum? Fuga dicta quam molestiae laborum?</p>
                  
                  </div>
                  </div>
                  
                  <div className="flex pb-2 items-center justify-center">
                    <img src="Assets/photo.png" className='w-[70%]' alt="" />
                  </div>
                    
                  </div>
                </div>
                </div> */}
      </div>
    </div>
  );
};

export default Testimonials;



import React from 'react'
import { ContactUs, Topmate } from '../Components'

const Contact = () => {
  return (
    <div>
    <ContactUs/>
    {/* <Topmate /> */}
      
    </div>
  )
}

export default Contact

import React from "react";

const Time = () => {
  return (
    <div className="px-[20%] py-[6%] relative">
      <h1 className="text-5xl font-tragic text-blue text-center pb-[2%]">
        Timeline
      </h1>
      <p className="text-center pb-[5%] font-incon lg:text-[1.5vw] md:text-lg text-2xl">
        Get to see our journey from inception till date
      </p>

      <div className="grid sm:grid-cols-2 ">
        <div className=" "></div>
        <div className="border-l-2 border-darkergry border-dashed  p-4 my-1"></div>

        {/* /part 1 */}

        <div className="relative border-l-2 sm:border-none border-dashed border-darkergry">
          <h1 className="sm:pl-[33%] pl-[5%] text-red font-tragic sm:text-[2vw]">
            VO: CPC Ottawa
          </h1>
          <span className="absolute top-[4%]  w-[1.5vw] h-[1.5vw] bg-blue-100 rounded-full bg-red right-[99%] sm:-right-[2.8%] ring-4 ring-black dark:ring-gray-900 dark:bg-blue-900"></span>

          <div className="flex flex-col items-start sm:items-end sm:justify-end  px-4 py-4">
            <img src="/Assets/t1.png" className="w-[70%]" alt="" />
          </div>
        </div>
        <div className="border-l-2 border-darkergry border-dashed  p-4"></div>

        {/* part 2 */}
        <div className="border-l-2 sm:border-none border-dashed border-darkergry   sm:p-4"></div>
        <div className="relative border-l-2 border-darkergry border-dashed my-1">
          <h1 className="pl-[5%] text-red font-tragic sm:text-[2vw]">
            Secondary
          </h1>
          <span className="absolute top-[4%]  w-[1.5vw] h-[1.5vw] bg-blue-100 rounded-full bg-red -left-[1.8%] sm:-left-[2.8%] ring-4 ring-black dark:ring-gray-900 dark:bg-blue-900"></span>

          <div className="flex flex-col items-start justify-start  px-4 py-4">
            <img src="/Assets/t2.png" className="w-[70%]" alt="" />
          </div>
        </div>

        {/* part 3 */}
        <div className="relative border-l-2 sm:border-none border-dashed border-darkergry">
          <h1 className="sm:pl-[33%] pl-[5%] text-red font-tragic sm:text-[2vw]">
            ITA - 26th Oct 2022
          </h1>
          <span className="absolute top-[4%]  w-[1.5vw] h-[1.5vw] bg-blue-100 rounded-full bg-red right-[99%] sm:-right-[2.8%] ring-4 ring-black dark:ring-gray-900 dark:bg-blue-900"></span>

          <div className="flex flex-col items-start sm:items-end sm:justify-end  px-4 py-4">
            <img src="/Assets/t3.png" className="w-[70%]" alt="" />
          </div>
        </div>
        <div className="border-l-2 border-darkergry border-dashed  p-4"></div>

        {/* part 4 */}
        <div className="border-l-2 sm:border-none border-dashed border-darkergry   sm:p-4"></div>
        <div className="relative border-l-2 border-darkergry border-dashed my-1">
          <h1 className="pl-[5%] text-red font-tragic sm:text-[2vw]">
            AOR - 9th Dec 2022
          </h1>
          <span className="absolute top-[4%]  w-[1.5vw] h-[1.5vw] bg-blue-100 rounded-full bg-red -left-[1.8%] sm:-left-[2.8%] ring-4 ring-black dark:ring-gray-900 dark:bg-blue-900"></span>

          <div className="flex flex-col items-start justify-start  px-4 py-4">
            <img src="/Assets/t4.png" className="w-[70%]" alt="" />
          </div>
        </div>

        {/* part 5 */}
        <div className="relative border-l-2 sm:border-none border-dashed border-darkergry">
          <h1 className="sm:pl-[33%] pl-[5%] text-red font-tragic sm:text-[2vw]">
            Medical - 12th Dec 2022
          </h1>
          <span className="absolute top-[4%]  w-[1.5vw] h-[1.5vw] bg-blue-100 rounded-full bg-red right-[99%] sm:-right-[2.8%] ring-4 ring-black dark:ring-gray-900 dark:bg-blue-900"></span>

          <div className="flex flex-col items-start sm:items-end sm:justify-end  px-4 py-4">
            <img src="/Assets/t5.png" className="w-[70%]" alt="" />
          </div>
        </div>
        <div className="border-l-2 border-darkergry border-dashed  p-4"></div>

        {/* part 6 */}
        <div className="border-l-2 sm:border-none border-dashed border-darkergry   sm:p-4"></div>
        <div className="relative border-l-2 border-darkergry border-dashed my-1">
          <h1 className="pl-[5%] text-red font-tragic sm:text-[2vw]">
            Biometrics - 20th Dec 2022
          </h1>
          <span className="absolute top-[4%]  w-[1.5vw] h-[1.5vw] bg-blue-100 rounded-full bg-red -left-[1.8%] sm:-left-[2.8%] ring-4 ring-black dark:ring-gray-900 dark:bg-blue-900"></span>

          <div className="flex flex-col items-start justify-start  px-4 py-4">
            <img src="/Assets/t6.png" className="w-[70%]" alt="" />
          </div>
        </div>

        {/* part 7 */}
        <div className="relative border-l-2 sm:border-none border-dashed border-darkergry">
          <h1 className="sm:pl-[33%] pl-[5%] text-red font-tragic sm:text-[2vw]">
            PPR - 07th Feb 2023
          </h1>
          <span className="absolute top-[4%]  w-[1.5vw] h-[1.5vw] bg-blue-100 rounded-full bg-red right-[99%] sm:-right-[2.8%] ring-4 ring-black dark:ring-gray-900 dark:bg-blue-900"></span>

          <div className="flex flex-col items-start sm:items-end sm:justify-end  px-4 py-4">
            <img src="/Assets/t7.png" className="w-[70%]" alt="" />
          </div>
        </div>
        <div className="border-l-2 border-darkergry border-dashed  p-4"></div>

        {/* part 8 */}
        <div className="border-l-2 sm:border-none border-dashed border-darkergry   sm:p-4"></div>
        <div className="relative border-l-2 border-darkergry border-dashed my-1">
          <h1 className="pl-[5%] text-red font-tragic sm:text-[2vw]">
            Passport Submission - 08th Feb 2023
          </h1>
          <span className="absolute top-[4%]  w-[1.5vw] h-[1.5vw] bg-blue-100 rounded-full bg-red -left-[1.8%] sm:-left-[2.8%] ring-4 ring-black dark:ring-gray-900 dark:bg-blue-900"></span>

          <div className="flex flex-col items-start justify-start  px-4 py-4">
            <img src="/Assets/t8.png" className="w-[70%]" alt="" />
          </div>
        </div>

        {/* part 9 */}
        <div className="relative border-l-2 sm:border-none border-dashed border-darkergry">
          <h1 className="sm:pl-[33%] pl-[5%] text-red font-tragic sm:text-[2vw]">
            Passport Collection - 24th Feb 2023
          </h1>
          <span className="absolute top-[4%]  w-[1.5vw] h-[1.5vw] bg-blue-100 rounded-full bg-red right-[99%] sm:-right-[2.8%] ring-4 ring-black dark:ring-gray-900 dark:bg-blue-900"></span>

          <div className="flex flex-col items-start sm:items-end sm:justify-end  px-4 py-4">
            <img src="/Assets/t9.png" className="w-[70%]" alt="" />
          </div>
        </div>
        <div className="border-l-2 border-darkergry border-dashed  p-4"></div>
      </div>
    </div>
  );
};

export default Time;

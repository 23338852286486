import React from "react";
import { NavLink } from "react-router-dom";

const index = () => {
  // const {pathname} = useLocation();

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <section className="grid">
      {links.map((link) => (
        <NavLink
          to={link.link}
          onClick={scrollToTop}
          className="hover:bg-red hover:text-white flex justify-center items-center font-averia text-red navlink text-center border-y-[1px] border-red hover:border-white">
          <div className="-rotate-90">{link.text}</div>
        </NavLink>
      ))}
    </section>
  );
};

export default index;

const links = [
  {
    text: "Who we are?",
    link: "/",
  },
  {
    text: "What we train?",
    link: "/what",
  },
  {
    text: "How we train?",
    link: "/info",
  },
  {
    text: "My Journey",
    link: "/timeline",
  },
  {
    text: "Contact us",
    link: "/contact",
  },
];

import React from "react";

const Landing = () => {
  return (
    <div
      className="w-full bg-lightgry relative md:pt-24 pt-16"
      style={{
        backgroundImage: `url('Assets/bg1.png')`,
        backgroundSize: "cover",
        backgroundPosition: "c",
      }}
    >
      <div></div>

      <div className="relative w-full">
        <div className="flex absolute w-full justify-between ">
          <img
            className="w-[30%] h-[30%] md:w-[15%] md:h-[15%]"
            src="Assets/grp1.png"
            alt=""
          />
          <img
            className="w-[30%] h-[30%] md:w-[15%] md:h-[15%]"
            src="Assets/grp2.png"
            alt=""
          />
        </div>

        <div className=" grid md:grid-cols-2  pb-[10%] pt-[5%]">
          <div className=" px-4 xs:px-[10vw] md:px-0 order-2 md:order-1 md:pl-[20%]  pt-[15%] ">
            <h1 className="md:text-3xl lg:text-6xl text-4xl sm:w-[90%] md:w-[100%] font-tragic text-blue leading-relaxed lg:leading-normal">
              Dream of living in Canada? Let’s make it happen!
            </h1>
            <p className="lg:text-[1.5vw] md:text-lg text-2xl leading-relaxed lg:leading-normal sm:w-[80%] font-incon xl:w-[80%] pt-6 pb-10">
              Your one-stop destination to ace French TEF/TCF exams is here.
            </p>
            <div className="pr-[15%] flex relative">
              <img src="/Assets/grp10.png" className="w-[50%] md:w-[60%] lg:w-[50%]" alt="" />
              <div className="text-white left-6 md:left-4 lg:left-8 absolute top-3 md:top-2 lg:top-4 lg:text-2xl  font-tragic ">
                Scroll to Learn More!
              </div>
            </div>
          </div>
          <div className="order-1 md:order-2 flex justify-end  items-center">
            <div className="">
              <img
                src="Assets/grp3.png"
                className="relative -right-[10vw] top-8 float_up"
                alt=""
              />
              <img
                src="Assets/grp8.png"
                className="w-4 relative left-[1vw] top-[25vh] float_side"
                alt=""
              />
              <img
                src="Assets/grp6.png"
                className=" relative left-[1vw] top-[23vw] float_left"
                alt=""
              />

              <img
                src="Assets/grp11.png"
                className=" w-4 relative left-[25vw] -top-[24vh] float_right"
                alt=""
              />
              <img
                src="Assets/grp4.png"
                className="relative -right-[35vw] -top-[16vh] w-8 float_down"
                alt=""
              />
              <img
                src="Assets/grp5.png"
                className=" relative -right-[30vw] top-[15vw] w-14 float_up"
                alt=""
              />
            </div>

            <img src="Assets/img1.png" className=" w-[90%]" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;

import React from "react";

const Special = () => {
  return (
    <div className=" ">
      <div className="grid pt-16 pb-4 md:px-[5vw] lg:px-[7vw] gap-10 md:grid-cols-2">
        <div className="relative flex justify-center">
          <img
            src="Assets/img2.png"
            className="object-scale-down overflow-hidden w-[70%] md:w-full"
            alt=""
          />
          <img
            className="absolute w-[3%] bottom-[10vw] md:top-[20vw] lg:top-[13vw] left-6 md:-left-[3vw] float_up"
            src="Assets/grp8.png"
            alt=""
          />
          <img
            className="absolute w-[8%] md:-top-[0vw] left-[20vw] -top-[8vw] md:left-[3vw] float_up"
            src="Assets/grp3.png"
            alt=""
          />
          <img
            className="absolute w-[4%] -top-[10vw] md:-top-[4vw] float_up md:left-[17vw]"
            src="Assets/grp24.png"
            alt=""
          />
          <img
            className="absolute w-[6%] -top-[5vw] md:top-0 right-[23vw] md:left-[29vw] float_up"
            src="Assets/grp4.png"
            alt=""
          />
          <img
            className="absolute w-[7%] top-[18vw] right-4 lg:top-[12vw] md:-right-[4vw] float_up"
            src="Assets/grp5.png"
            alt=""
          />
        </div>
        <div className="px-4 xs:px-[10vw] md:px-[3vw] relative">
          <div className="relative">
            <h1 className="md:text-3xl lg:text-6xl text-4xl sm:w-[90%] font-tragic text-blue leading-normal">
              Is it a Oui or a Si?
            </h1>
            <img
              src="Assets/grp20.png"
              className="w-[35%] absolute  xl:-top-20 md:-top-12 lg:-top-16 -left-20 float_side"
              alt=""
            />
          </div>
          <p className=" sm:w-[90] lg:text-[1.5vw] md:text-lg text-2xl lg:w-[100%] lg:leading-relaxed text-justify font-incon xl:w-[85%] pt-6 pb-10">
            Ace your French language skills and achieve native-speaking
            proficiency with courses fine-tuned to help you win!
          </p>
          <img
            src="Assets/grp22.png"
            className="absolute -bottom-4 left-2"
            alt=""
          />
          <img
            src="Assets/grp23.png"
            className="absolute -top-[5vw] right-[5vw] float_right"
            alt=""
          />
        </div>
      </div>
      <img
        className="w-[10%] relative bottom-14 md:bottom-6 left-[70vw] float_left"
        src="/Assets/grp21.png"
        alt=""
      />
    </div>
  );
};

export default Special;

import React from 'react'
import { ExamUs, Module, Next, Testimonials, WhatTrain } from '../Components'

const What = () => {
  return (
    <div>
    <WhatTrain/>
    <Module />
    <Testimonials />
    <Next/>
    <ExamUs/>
      
    </div>
  )
}

export default What

import React from "react";

const WhatTrain = () => {
  return (
    <div
      className="h-scren relative md:pt-[10%] lg:pt-[5%] pt-[20%]"
      style={{
        backgroundImage: `url('/Assets/bg3.png')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div>
        <img
          src="/Assets/grp63.png"
          className="absolute right-[45%] w-[12%] top-[15%] float_left"
          alt=""
        />
        <img
          src="/Assets/grp40.png"
          className="absolute  w-[12%] left-[10%] top-[13%] right float_right"
          alt=""
        />
      </div>
      <div className="grid max-w[1580px] mx-auto px-[10vw] pb-[6vw] pt-[10vw] lg:grid-cols-2">
        <div className="order-last lg:order-first">
          <h1 className="text-red lg:text-6xl text-4xl md:pt-20 font-tragic">
            What we train
          </h1>
          <h1 className=" lg:pl-1 pt-[3vw] pb-[1vw] md:text-[2.5vw] text-md font-averia">
            Our Training Program{" "}
          </h1>
          <p className=" sm:w-[90] text-justify font-incon lg:text-[1.5vw] md:text-lg text-2xl lg:pl-2 pb-10">
          In our comprehensive French training program, we focus on developing grammar, vocabulary, speaking, listening, reading, and writing skills while emphasizing cultural understanding and practical language use. Our training covers all proficiency levels, from beginner to advanced, and also prepares students for French interviews, as well as for various levels of the DELF (A1, A2, B1, B2) and TEF/TCF exams.
          </p>
        </div>
        <div className="flex relative justify-center pb-12 md:pb-0 items-center ">
          <div className="w-full flex relative   items-center justify-center">
            <img className="pl-10" src="/Assets/grp741.png" alt="" />

            
          </div>
          <img
            src="/Assets/grp18.png"
            className="transform rotate-45 float_up absolute w-[5%] left-[10%]  bottom-[35%]"
            alt=""
          />
          <img
            src="/Assets/grp65.png"
            className="  absolute w-[5%] left-[13%] float_up top-[30%]"
            alt=""
          />
          <img
            src="/Assets/grp23.png"
            className="transform -rotate-45  absolute w-[5%] left-[23%] float_up top-[4%]"
            alt=""
          />
          <img
            src="/Assets/grp52.png"
            className="transform -rotate-45 float_up absolute w-[5%] right-[28%]  top-0"
            alt=""
          />
          <img
            src="/Assets/grp68.png"
            className=" absolute w-[13%] right-[8%] float_up bottom-[50%]"
            alt=""
          />
          <img
            src="/Assets/grp64.png"
            className="  absolute w-[30%] right-[0%] -top-[18%]"
            alt=""
          />
          <img
            src="/Assets/grp66.png"
            className="  absolute  -right-[10%] float_up bottom-[30%]"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default WhatTrain;

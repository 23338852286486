import React from "react";

const ContactUs = () => {

  const connectButton = document.getElementsByClassName('topmate-button');
  // console.log(connectButton);

  const connectOpen = () => {
    if(connectButton[0]){
      connectButton[0].click();
      // console.log(connectButton);
    }
  }

  return (
    <div className="relative pt-20 lg:pt-0 overflow-hidden">
      <img
        src="/Assets/grp59.png"
        className="absolute w-[13vw] top-[7vw] -left-4 sm:-left-8 lg:-left-14"
        alt=""
      />
      <div className="grid pb-[20vw] py-[10vw] px-4 sm:px-[12vw] lg:px-[14vw] lg:grid-cols-2">
        <div className="flex lg:pl-6 pb-[4vw] lg:pt-[4vw] flex-col gap-6">
          <h1 className="text-red md:text-3xl lg:text-6xl text-4xl leading-relaxed font-tragic">
            Speak To An Expert Today!
          </h1>
          <p className="lg:text-[1.5vw] md:text-lg text-2xl lg:pb-[2vw] leading-relaxed font-incon font-medium">
            Don't let basic French hold back your chance for a better life. Let
            our experts boost your French skills and get you ready for the
            TEF/TCF exams in no time. The sooner you start, the better you
            become. Don't miss out— you're just one small step away from living
            your Canadian dream.
          </p>
          <div className="relative flex flex-col justify-center items-start" onClick={connectOpen}>
            <button className="absolute text-xl text-white left-6 font-tragic">
              Book a Call Now
            </button>
            <img src="/Assets/grp34.png" className="w" alt="" />
          </div>
        </div>
        <div className="flex relative justify-center items-center">
          <img className="md:pl-8" src="/Assets/grp58.png" alt="" />
          <img
            src="/Assets/grp60.png"
            className="absolute w-[90%] md:w-[60%] lg:w-[90%] sm:w-[30vw] sm:top-[2vw] sm:left-[4vw] -bottom-[20vw] -top[5vw] lg:top-[5vw] lg:left-[2vw] md:left-32"
            alt=""
          />
          {/* <img src="/Assets/grp60.png" className="absolute sm:right-[11vw] md:right-[13vw] bottom-0 sm:top-[10vw] sm:w-[40vw] w-[60vw] md:w-[30vw]" alt="" /> */}
        </div>
      </div>
      <img
        src="/Assets/grp57.png"
        className="absolute lg:bottom-0 md:bottom-[6%] bottom-[3%] scale-150 lg:scale-100 lg:w-full"
        alt=""
      />
    </div>
  );
};

export default ContactUs;

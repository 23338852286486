import React from "react";

const Jack = () => {
  return (
    <div className=" relative">
      <img
        src="Assets/grp19.png"
        className="absolute -left-[4vw] -top-6 lg:w-[15vw] w-[40%] md:w-[25%] float_up"
        alt=""
      />
      <img
        src="Assets/grp18.png"
        className="absolute  md:top-12 -top-2 left-[47vw] md:w-[2.8vw] w-[10%] float_di "
        alt=""
      />
      <img
        src="Assets/grp16.png"
        className="absolute  top-32 md:w-[1.5vw] left-[64vw]  "
        alt=""
      />
      <img
        src="Assets/grp17.png"
        className="absolute  md:top-[13vw] top-[] md:w-[3vw] left-[78vw] w-[8%] float_side  "
        alt=""
      />

      <div className="md:grid pt-[10vw]  grid-cols-11  px-4 xs:px-[10vw]  order-2 md:order-1 md:px-[9vw]">
        <div className="col-span-5 ">
          <h1 className="md:text-3xl lg:text-[3.5rem] text-4xl text-blue font-tragic  pb-8 leading-relaxed lg:w-[30vw] lg:leading-normal">
            Know French Grammar, But Can’t Speak?
          </h1>
          <p className="lg:w-[25vw] lg:text-[1.5vw] md:text-lg text-2xl font-incon leading-relaxed lg:leading-relaxed">
            Overcoming language barriers is now easy! Let our experts guide you
            through your TEF/TCF preparations, making your move to Canada, a
            breeze.
          </p>
          {/* <p className="pt-4">We firmly believe that ..</p> */}
        </div>
        <div className="col-span-6 flex flex-col justify-center items-center relative ">
          <img
            src="Assets/grp15.png"
            className="absolute md:top-[2vw] lg:-top-[0vh] 2xl:-top-[10vh] xl:-top-[2vw] right-[4vw] w-[8vw] float_up"
            alt=""
          />

          <img
            src="/Assets/grp12.png"
            className="xs:w-[38vw] w-[80%] md:pt-0 pt-[10vw]"
            alt=""
          />
          <img
            src="Assets/grp14.png"
            className="absolute top-[13vh] left-[2vw] w-[17vw] float_up"
            alt=""
          />
        </div>
      </div>
      <img
        src="Assets/grp13.png"
        className="relative -left-4 bottom-  sm:w-[10vw] w-[15vh] float_up"
        alt=""
      />
    </div>
  );
};

export default Jack;

import React from "react";

const Courses = () => {

  const connectButton = document.getElementsByClassName('topmate-button');
  // console.log(connectButton);

  const connectOpen = () => {
    if(connectButton[0]){
      connectButton[0].click();
      // console.log(connectButton);
    }
  }

  return (
    <div
      className="pb-16 relative"
      style={{
        backgroundImage: `url('/Assets/grp35.png')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <h1 className="text-center pt-[6vw] md:text-3xl lg:text-6xl text-4xl font-tragic text-blue">
        Know Our Courses
      </h1>

      <div className="grid  pt-[7vw] px-[5vw] md:grid-cols-3">
        <div className="relative flex flex-col justify-center items-center gap-8">
          <img className="hover:scale-105" src="/Assets/grp30.png" alt="" />
          <h1 className="lg:text-[1.8vw] md:text-lg text-2xl font-tragic">TEF - Beginner to Advance</h1>
          <div className="relative flex flex-col justify-center items-center pb-10" onClick={connectOpen}>
            <button className="absolute lg:text-[1.5vw] md:text-lg text-2xl text-white font-ver">
              Enroll Now
            </button>
            <img src="Assets/grp34.png" className="w-[70%]" alt="" />
          </div>
          <img
            src="/Assets/left.png"
            className="absolute sm:-top-[7.5vw] -top-[22vw] -left-5 w-[32%] float_up"
            alt=""
          />
        </div>
        <div className=" flex flex-col justify-center items-center gap-8">
          <img className="hover:scale-105" src="/Assets/grp31.png" alt="" />
          <h1 className="lg:text-[1.8vw] md:text-lg text-2xl font-tragic">TEF - Speaking & Writing</h1>
          <div className="relative flex flex-col justify-center items-center pb-10" onClick={connectOpen}>
            <button className="absolute lg:text-[1.5vw] md:text-lg text-2xl text-white font-ver">
              Enroll Now
            </button>
            <img src="Assets/grp34.png" className="w-[70%]" alt="" />
          </div>
        </div>
        <div className="relative flex flex-col justify-center items-center gap-8">
          <img className="hover:scale-105" src="/Assets/grp30.png" alt="" />
          <h1 className="lg:text-[1.8vw] md:text-lg text-2xl font-tragic">TCF - Listening & Reading</h1>
          <div className="relative flex flex-col justify-center items-center pb-10" onClick={connectOpen}>
            <button className="absolute lg:text-[1.5vw] md:text-lg text-2xl text-white font-ver">
              Enroll Now
            </button>
            <img src="Assets/grp34.png" className="w-[70%]" alt="" />
          </div>
          <img
            src="/Assets/right.png"
            className="absolute sm:-top-[7.5vw] -top-[22vw] -right-5 w-[32%] float_up"
            alt=""
          />
        </div>
      </div>
      {/* <p className="text-center font-semibold text-lg pt-[8vw] pb-[4vw]">Special discount for Canadian students</p> */}
    </div>
  );
};

export default Courses;

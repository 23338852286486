import React from 'react'
import { ExamUs, Method, Stories, Train } from '../Components'

const Info = () => {
  return (
    <div>
    <Train/>
    <Method/>
    <Stories/>
    <ExamUs/>
      
    </div>
  )
}

export default Info

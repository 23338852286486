import React from "react";

const ExamUs = () => {

  const connectButton = document.getElementsByClassName('topmate-button');
  // console.log(connectButton);

  const connectOpen = () => {
    if(connectButton[0]){
      connectButton[0].click();
      // console.log(connectButton);
    }
  }
  
  return (
    <div className="bg-lightgry py-32 px-[10vw] rounded-sm">
      <div className="bg-darkergry py-10 grid md:grid-cols-5">
        <div className="md:col-span-3 pl-[6vw] order-2 md:order-1 flex flex-col pr-4 sm:pr-0 gap-8 pt-10">
          <h1 className="text-blue font-tragic md:text-3xl lg:text-6xl text-4xl">
            Get exam ready with us
          </h1>
          <p className="lg:w-[30vw] lg:text-[1.5vw] md:text-lg text-2xl text-justify sm:text-left font-incon">
            Over 1000 aspirants have benefited from our comprehensive, detailed
            courses in acing the TEF Canada exam and have obtained Canadian PR.
            You could be next!
          </p>

          <div className="relative flex flex-col justify-center items-start" onClick={connectOpen}>
            <button className="absolute text-white lg:text-[1.3vw] md:text-base text-xl left-10 font-ver">
              Enroll Now
            </button>
            <img src="Assets/grp34.png" className="w" alt="" />
          </div>
        </div>
        <div className="col-span-2 order-1 md:order-2 flex justify-start items-center    ">
          <img
            className="md:w-[20vw] sm:w-[25vw] lg:w-[16vw] w-[50vw] float_right relative md:static left-[12%] sm:left-[30%]"
            src="/Assets/grp56.png"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default ExamUs;

import React from 'react'
import { Courses, ExamUs, Jack, Landing, Special, Trainer } from '../Components'

const Home = () => {
  return (
    <div>
    <Landing/>
    <Jack/>
    <Special/>
    <Trainer/>
    <Courses/>
    <ExamUs/>
      
    </div>
  )
}

export default Home
